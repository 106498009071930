import logo from './logo.svg';
import React, { Component }  from 'react';
import cleBackground from './cle.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <p>
          Cleveland Loves to Code
        </p>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <img src={cleBackground} >

        </img>
       
       
      </header>
    </div>
  );
}

export default App;
